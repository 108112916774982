.weight-input-custom.ui.input input {
  font-weight: bold;
  font-size: 24px;
  padding: 7px;
  text-align: center;
  background-color: #bbe3fd;
  color: black;
  text-orientation: sideways;
  width: 5em;
}

/* Table */

.weight-table-custom {
  overflow: hidden;
  border: 1px solid #2b98e1;
  border-radius: 3px;

  .rt-thead .rt-th {
    border-right: 0;
  }
  .rt-th {
    border: 0;
  }
  .rt-thead.-header {
    background-color: var(--text-light-blue-20);
    color: #1d252b;
    box-shadow: none;
    border-bottom: 1px solid #2b98e1;
  }
  .rt-tr:not(.blueSelectedRow):not(.selected):hover .rt-td {
    background: transparent !important;
  }
  .rt-tbody .rt-tr-group {
    border-bottom: 0;
  }
  .rt-th,
  .rt-td {
    padding: 7px 8px;
  }

  &.definition {
    .rt-thead.-header {
      border-bottom: 1px solid #5ca7d9;
    }
    .rt-th.rt-resizable-header {
      border-bottom: 1px solid #5ca7d9;
    }
    .rt-th:nth-child(1) {
      border-right: 2px solid #5ca7d9;
    }
    .rt-tr .rt-td:nth-child(1) {
      background-color: #a6d5f8;
      border-right: 2px solid #5ca7d9;
      border-bottom: 1px solid #a6d5f8;
    }
  }
  &.web-mom {
    .rt-table {
      ::-webkit-scrollbar {
        display: block;
      }
    }
    // border-bottom-width: 0;
    border-top-width: 0;
    .rt-thead.-header {
      background-color: var(--bg-medium-blue);
      color: white;
    }
    .rt-tbody .rt-tr-group {
      border-bottom: solid 1px var(--bg-medium-blue-50);
    }
  }
  &.vaccine {
    .rt-tr-group {
      div.rt-td:nth-child(3) {
        border-right: 1px solid #5ca7d9;
      }
      div.rt-td:nth-child(4),
      div.rt-td:nth-child(5),
      div.rt-td:nth-child(6),
      div.rt-td:nth-child(7) {
        padding: 0;

        .child-column {
          border-bottom: 1px solid #5ca7d9;
          padding: 7px 8px;
          &:last-of-type {
            border: none;
          }
        }
      }
    }
  }
}

/* Tab */
.weight-tab-custom {
  width: 100%;
  background-color: white;
  z-index: 1;
  &.dm {
    position: fixed;
  }

  .ui.pointing.secondary.menu {
    border-bottom: 0;
    margin-bottom: 2.5px;
    &:hover,
    .active.item {
      border-color: #0072bc;
      border-bottom-width: 3.5px;
      color: #51aeea;
    }

    .item {
      border-color: rgb(34 36 38 / 10%);
      border-bottom-width: 3px;
      color: #9e9e9e;
    }
  }
}

// Global
.react-swipeable-view-container > .dm-view {
  height: calc(100vh - 135px);
  overflow-x: hidden !important;
  margin-top: 42px;
  height: calc(var(--dm-vh, 1vh) * 100 - 135px);
}
// .react-swipeable-view-container > .child-view {
//   // height: calc(100vh);
//   // overflow: auto;
//   // margin-top: 82px;
//   // padding-bottom: 65px;
// }
.react-swipeable-view-container {
  .food-list {
    height: calc(100vh - 53px);
    margin-top: 0px;
  }
  .food-detail {
    height: calc(100vh - 53px);
    margin-top: 0;
  }
  .add-food {
    height: calc(100vh - 94px);
  }
}
//

.hidden-view {
  &.two {
    .react-swipeable-view-container .food-list:nth-child(2) {
      display: none;
    }
  }
  &.three {
    .react-swipeable-view-container .food-list:nth-child(3) {
      display: none;
    }
  }
}

.ui.progress .bar {
  min-width: 0;
}

.time-custom-react-picker {
  width: 99px;
  & + div {
    margin: auto 0 !important;
  }
  .react-time-picker__wrapper {
    border-radius: 0.28571429rem;
    height: 37.8px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    overflow: hidden;
    .react-time-picker__inputGroup {
      display: flex;
      align-items: center;
      justify-content: center;
      .react-time-picker__inputGroup__input {
        width: 10px;
        height: 17px;
        border: 0;
      }
      .react-time-picker__inputGroup__divider {
        color: var(--primary-theme-color);
      }
    }
    .react-time-picker__clear-button {
      display: none;
    }
  }
  &.padding-input {
    .react-time-picker__wrapper .react-time-picker__inputGroup .react-time-picker__inputGroup__leadingZero {
      padding-left: 13px;
    }
    .react-time-picker__wrapper .react-time-picker__inputGroup .react-time-picker__inputGroup__input--hasLeadingZero {
      width: 9px;
      padding-left: 1px;
      padding-right: 13px;
    }
  }
  &.padding-divider {
    .react-time-picker__inputGroup__divider {
      margin: 0 10px;
    }
  }
}

.exercise-dm-custom {
  .exercise-detail {
    display: grid;
    align-items: center;
    padding-left: 15px;
    div {
      display: grid;
      grid-template-columns: 110px auto;
      label {
        font-weight: bold;
        color: var(--primary-theme-color);
      }
    }
  }
  .ui.button {
    &.primary {
      background-color: var(--primary-theme-color);
      color: white;
    }
  }
  .font-primary {
    color: var(--primary-theme-color);
  }
  padding-bottom: 35px;
}

.ChildGrowth-form {
  padding: 50px 30px 0 40px !important;
  & > div {
    display: grid;
    grid-template-columns: 50px auto;
    padding-bottom: 1rem;
    width: 100%;
    .Label-input {
      font-weight: bold;
      font-size: 0.875rem;
      display: flex;
      color: rgb(116, 116, 116);
      padding-top: 10px;
    }
    input {
      text-align: right !important;
    }
  }
}

.box-header-chart-dm {
  .header-top {
    display: grid;
    grid-template-columns: 54% 46%;
    padding: 10px 0 0;
    & > div:nth-child(1) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(82px, 1fr));
      padding: 10px 0 0 10px;
    }

    &.full {
      grid-template-columns: 100%;
    }

    .duration {
      label {
        margin: 10px 0px 0 0;
      }
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      padding: 10px;
      padding-left: 0;
      .dropdown {
        transform: scale(0.9);
        border-radius: 5px;
      }
    }

    .div-rect,
    .div-triangle,
    .div-ellipse,
    .div-star {
      width: 0.75rem;
      height: 0.75rem;
      position: absolute;
      top: 4px;
    }

    .box-label {
      display: flex;
      align-items: baseline;
      position: relative;
      margin-bottom: 1px;
      label {
        font-size: 0.875rem;
        margin-left: 1.2rem;
      }
      &.line label {
        margin-left: 0.1rem;
      }
      &.star {
        .div-star {
          top: 1px;
        }
        label {
          margin-left: 1.3rem;
        }
      }
      &.image {
        img {
          width: 1rem;
          position: absolute;
          height: 1rem;
          top: 0.17rem;
          left: -0.11rem;
        }
      }
    }

    .div-triangle {
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }
    .div-ellipse {
      clip-path: ellipse(33% 50% at 50% 50%);
    }
    .div-star {
      clip-path: polygon(50% 0%, 66% 34%, 98% 35%, 75% 60%, 82% 98%, 50% 78%, 16% 100%, 26% 59%, 2% 35%, 35% 34%);
      width: 1rem;
      height: 1rem;
      &::after {
        content: "N";
        color: white;
        position: absolute;
        transform: scale(0.6);
        margin-left: 0.17rem;
        margin-top: -0.125rem;
      }
    }
    .div-line {
      display: flex;
      align-items: center;
      .point {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50rem;
      }
      .line {
        width: 15px;
        height: 1.5px;
      }
      &:last-of-type {
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
  }

  @media (max-width: 345px) {
    .header-top {
      grid-template-columns: 52% 48%;
      .duration {
        label {
          font-size: 0.9rem;
          margin: 8px 0 0 0;
        }

        .dropdown {
          transform: scale(0.85);
        }
      }
    }
  }

  .box-date {
    display: flex;
    justify-content: center;
    color: var(--text-dark-grey);
    padding: 10px 0 0px;
    filter: drop-shadow(0px 6px 3px rgba(149, 197, 228, 1));
    align-items: baseline;
    & > i:not(.disabled) {
      cursor: pointer;
    }
    & > div {
      padding: 0px 10px;
      min-width: 167.69px;
      text-align: center;
    }
  }
  .header-chart-label {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    color: var(--primary-theme-color);
    padding: 10px 8px 5px 16px;
  }
}

.box-footer-chart-dm {
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 10px;
  .btn-see-all {
    padding: 5px 20px;
    border-radius: 50rem;
    margin: 0;
    height: 1.875rem;
  }
}
