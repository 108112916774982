
.main-postpone {
  $arc: "rc";
  $view_cell: "rc_body-cell";
  $view_cell_value: "rc_body-cell_value";
  $body_day_month: "rc_body-days-of-month";
  $view_weekdays_cell: "rc_body-weekdays_cell";
  $view_row: "rc_body-row";
  $borderRadius: 0.75rem;
  $weekdays_body : "rc_body-weekdays";

  .#{$arc}_root {
    padding: 10px 5px;
    width: 95%;
    background: #FFFFFF;
    box-shadow: 1px 8px 18px rgba(44, 44, 44, 0.1);
    border-radius: 16px !important;
    .#{$arc} {
      height: 88vw !important;
      width: 91vw !important;
    }
  }

  .#{$arc}_header_label.#{$arc}_header_label-days-of-month {
    box-shadow: none !important;
    flex: 0 !important;
  }

  .#{$arc}_header {
    align-items: center;
    justify-content: space-around;
  }
  .#{$weekdays_body} {
    border: 0px
  }

  .#{$arc}_header_nav {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: $borderRadius !important;

    &.#{$arc}_header_nav-next,
    &.#{$arc}_header_nav-prev {
      height: 85% !important;
      position: relative;
      span {
        visibility: hidden;
        position: relative;

        &::after {
          content: "";
          background-image: url("data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.08586 1.26893C0.985429 1.36915 0.905752 1.4882 0.851389 1.61925C0.797026 1.7503 0.769043 1.89079 0.769043 2.03268C0.769043 2.17456 0.797025 2.31505 0.851389 2.4461C0.905752 2.57716 0.985428 2.6962 1.08586 2.79643L5.28919 6.99976L1.08586 11.2031C0.883297 11.4057 0.7695 11.6804 0.7695 11.9668C0.7695 12.2533 0.883296 12.528 1.08586 12.7306C1.28841 12.9332 1.56314 13.0469 1.84961 13.0469C2.13607 13.0469 2.4108 12.9332 2.61336 12.7306L7.58586 7.75809C7.68629 7.65787 7.76596 7.53882 7.82033 7.40777C7.87469 7.27672 7.90267 7.13623 7.90267 6.99434C7.90267 6.85246 7.87469 6.71197 7.82033 6.58092C7.76596 6.44986 7.68629 6.33082 7.58586 6.23059L2.61336 1.25809C2.20169 0.846426 1.50836 0.846426 1.08586 1.26893Z' fill='%239F9F9F'/%3E%3C/svg%3E%0A");
          visibility: visible;
          position: absolute;
          top: 15%;
          left: 25%;
          width: 8px;
          height: 14px;
        }
      }
    }
    &.#{$arc}_header_nav-prev span::after {
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.91414 12.7311C7.01457 12.6309 7.09425 12.5118 7.14861 12.3808C7.20297 12.2497 7.23096 12.1092 7.23096 11.9673C7.23096 11.8254 7.20297 11.685 7.14861 11.5539C7.09425 11.4228 7.01457 11.3038 6.91414 11.2036L2.71081 7.00024L6.91414 2.79691C7.1167 2.59435 7.2305 2.31962 7.2305 2.03316C7.2305 1.7467 7.1167 1.47196 6.91414 1.26941C6.71158 1.06685 6.43686 0.953052 6.15039 0.953052C5.86393 0.953052 5.5892 1.06685 5.38664 1.26941L0.414142 6.24191C0.313713 6.34213 0.234037 6.46118 0.179673 6.59223C0.12531 6.72329 0.0973282 6.86377 0.0973282 7.00566C0.0973282 7.14754 0.12531 7.28803 0.179673 7.41908C0.234036 7.55014 0.313713 7.66918 0.414142 7.76941L5.38664 12.7419C5.79831 13.1536 6.49164 13.1536 6.91414 12.7311Z' fill='%239F9F9F'/%3E%3C/svg%3E%0A");
    }
  }

  .#{$view_cell} {
    background-color: transparent;
    .#{$view_cell_value} {
      background-color: transparent !important;
    }
    &.#{$arc}_disabled button:before {
      display: none !important;
    }

    &.#{$arc}_today {
      button {
        border-bottom-width: 0 !important;
      }
    }

    &.#{$arc}_wknd {
      button {
        color: #393939 !important;
        font-weight: 500 !important;
      }
    }

    &.#{$arc}_lr,
    &.#{$arc}_fr,
    &.#{$arc}_lc {
      button {
        color: #c4c4c4 !important;
        font-weight: normal !important;
      }
    }
    &.#{$arc}_active button {
      font-weight: 500 !important;
      color: #393939 !important;
    }
    &.#{$arc}_disabled button {
      color: #c4c4c4 !important;
      font-weight: normal !important;
    }
  }

  .#{$view_weekdays_cell} {
    font-weight: bold !important;
    color: #9f9f9f !important;
  }

  .#{$arc}_header span {
    font-weight: bold !important;
    color: #797878 !important;
    font-size: 16px !important;
  }

  .#{$view_cell} {
    &.#{$arc}_selected {
      // background-color: green;
      .#{$view_cell_value} {
        background-color: #0147a3 !important;
        border-radius: $borderRadius !important;
        position: relative;
        border: 1px solid rgba(1, 71, 163, 0.3) !important;
        button {
          color: #c4c4c4 !important;
          font-weight: normal !important;
        }
        // &::before {
        //   content: "";
        //   width: 12px;
        //   height: 12px;
        //   position: absolute;
        //   border-radius: 50px;
        //   right: -3px;
        //   top: -3px;
        //   background: rgba(1, 71, 163, 0.3);
        // }
      }

      &.#{$arc}_active .#{$view_cell_value} {
        // border: 1px solid #0147a3 !important;
        button {
          color: white !important;
          font-weight: bold !important;
        }

        &::before {
          background: #0147a3;
        }
      }

      // &.#{$arc}_wknd {
      //   .#{$arc}_view_cell_value {
      //     border: 1px solid #da0000;
      //     button {
      //       color: #da0000 !important;
      //       font-weight: bold;
      //     }
      //     &::before {
      //       background: #da0000;
      //     }
      //   }
      // }
      &.#{$arc}_highlight {
        .#{$arc}_#{$view_cell_value} {
          background-color: #ffadad !important;
          border-radius: $borderRadius !important;
          position: relative;
          border: 0;
          button {
            color: #da0000 !important;
            border-bottom: 0 !important;
            font-weight: normal !important;
          }
          &::before {
            display: none;
          }
        }
      }
    }

    &.#{$arc}_highlight {
      .#{$view_cell_value} {
        background-color: rgb(255, 233, 233) !important;
        // background-color: #ffadad !important;
        border-radius: $borderRadius !important;
        position: relative;
        border: 0;
        button {
          // color: #da0000 !important;
          color: rgba(218, 0, 0, 0.3) !important;
          border-bottom: 0 !important;
        }
      }
    }

    &.#{$arc}_highlight.rc_merge_disabled .#{$view_cell_value} {
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        background: rgb(255, 233, 233);
        left: -70%;
        position: absolute;
      }
    }

    &.#{$arc}_active.#{$arc}_highlight {
      .#{$view_cell_value} {
        background-color: #ffadad !important;
        button {
          color: #da0000 !important;
        }
      }
    }
  }

  .#{$view_row}
    .#{$arc}_highlight.#{$arc}_disabled:not(.#{$arc}_active)
    + .#{$arc}_highlight.#{$arc}_disabled:not(.#{$arc}_active) {
    .#{$view_cell_value} {
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        background: rgb(255, 233, 233);
        left: -70%;
        position: absolute;
      }
    }
  }

  .#{$view_row} .#{$arc}_active.#{$arc}_highlight + .#{$arc}_active.#{$arc}_highlight {
    .#{$view_cell_value} {
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        background: #ffadad;
        left: -70%;
        position: absolute;
      }
    }
  }

  .ui.icon.input.location-input-search {
    width: 100%;
    input {
      padding-left: 42px;
      border-radius: 25px;
      font-size: 17px;
      // box-shadow: 0px 0px 8px 1px rgba(131, 133, 135, 0.2);
    }
    .ui.circular.button {
      background-color: rgb(1, 71, 163);
      padding: 0px 9.98px;
      position: absolute;
      right: 2px;
      width: 37px;
      height: 37px;
      top: 4px;
    }
  }


  .calendar-Appointment{
      padding: 10px 5px;
      width: 90%;
      background: #FFFFFF;
      box-shadow: 1px 8px 18px rgba(44, 44, 44, 0.1);
      border-radius: 16px;

    .rc_header_nav {
      background: #FFFFFF;
      box-shadow: 0px 4px 8px rgba(44, 44, 44, 0.08) !important;
      border-radius: 8px !important;
      border: 0 !important;
    }
    .rc_header_label.rc_header_label-days-of-month {
      background: transparent;
      border: 0 ;
    }
  }

}