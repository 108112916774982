.tele_menu {
    padding: 0;
    font-size: 16px;
    .item {
        border-left: 10px solid transparent;
        & > div {
            font-size: 16px;
        }
    }
    .active.item{
        border-left-color: white;
    }

}

.ui.segment {
    border: 0px;
    box-shadow: none;
    border-radius: 0px;
}

.chat-list {
        padding: 0;
        font-size: 16px;
        font-weight: bold;
        color: "var(--primary-theme-color)";
        .active.item {
            & > div {
                background: #F4F6F7;
                display: flex;
                align-items: center;
                padding: 15px 10px;
            }
        }
        .item{
            & > div {
                display: flex;
                align-items: center;
                padding: 15px 10px;
            }

        }
}

.chat_search {
    display: flex;
    justify-content: center;
    margin: 20px 10px;
    .ui.search {
        width: 100%;
        .ui.input{
            width: 100%;
        }
        .ui.input>input {
            background: #F4F6F7;
            border-radius: 5px;
            border: 0px;
            margin: 0px;
        }
    }
}

.ChatBox {
    width: 100%;
    height: calc(100% - 42px);
    background-color: #F4F6F7;
}