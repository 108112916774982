.grey-label {
    .ui.basic.label{
        color: #747474;
    }

    input[type=text]{
        text-align: right; 
        color: "#4F4F4F";
    }

    h5.ui.header {
        color: #747474;
    }
}

.center-input{
    input[type=number]{
        text-align: center;
    }
}
