.date-center {
  .ui.icon.input {
    input {
      text-align: center;
      width: 200px;
    }
  }
}

.input-center {
  input{
    text-align: center !important;
    font-size: 20px;
    color: #4F4F4F;
  }
}

.weight-table-custom {
  overflow: hidden;
  border: 1px solid #2b98e1;
  border-radius: 3px;
  .rt-thead .rt-th {
    border-right: 0;
  }
  .rt-th {
    border: 0;
  }
  .rt-thead.-header {
    background-color: #a6d5f8;
    color: #1d252b;
    box-shadow: none;
  }
  .rt-noData {
    padding: 0px;
  }
}
