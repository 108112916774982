@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans+Thai:wght@100..900&family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.Mainhistory {
    .StepperAllergy {
        min-height: 24px;

        .css-rh92k-MuiPaper-root-MuiMobileStepper-root {
            justify-content: center;

            .css-26w9jf-MuiMobileStepper-dot {
                background-color: "#DA3535";
            }
        }
    }

    .StepperImpant {
        min-height: 24px;

        .css-rh92k-MuiPaper-root-MuiMobileStepper-root {
            justify-content: center;

            .css-26w9jf-MuiMobileStepper-dot {
                background-color: "var(--primary-theme-color)";
            }
        }
    }

    .own-class {
        color: white;
    }

    .history_search {
        .ui.input {
            width: 100%;
        }

        .ui.search .prompt {
            border-radius: 10px;
        }
    }

    .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
        margin-bottom: 0px;
        margin-top: 12px;
    }

    .css-15v22id-MuiAccordionDetails-root {
        padding: 16px 16px 8px 16px;
    }
}

.ImplantDetail {

    .card1 {
        margin-left: auto;
        margin-right: auto;
        padding: 1rem;
        left: 0;
        right: 0;
        background-color: white;
        border-radius: 12px;
        box-shadow: 2px 8px 21px 0px #0066FF17;
        margin-bottom: 1rem;
    }

    .card2 {
        margin-left: auto;
        margin-right: auto;
        padding: 16px;
        left: 0;
        right: 0;
        background-color: white;
        border-radius: 8px;
        border: 1px solid #E0EAF8;
        margin-bottom: 16px;
    }

    .ui.grid>.row {
        padding: 0;
    }

    .info-details {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 16px;
        // margin-top: 12px;
        // gap: 1rem;
    }

    .detail-item {
        display: flex;
        height: 85;
        flex-direction: row;
        justify-content: space-between;
        /* Ensures labels are left-aligned and values are right-aligned */
        padding-bottom: 0.5rem;
        /* Space between text and the border */
        max-width: 100%;
        box-sizing: border-box;
    }

    .label {
        font-size: 13px;
        font-weight: normal;
        font-family: Montserrat, Noto Sans Thai;
        line-height: 18px;
        color: #797878;
        flex: 1;
        margin-bottom: 0px;
    }

    .value {
        font-family: Montserrat, Noto Sans Thai;
        color: #393939;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        text-align: left;
        flex: 2;
    }

    .value-right {
        font-family: Montserrat, Noto Sans Thai;
        color: #393939;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        text-align: right;
        flex: 2;
    }
}