:root {
  --success: #00c300;
  --warning: #edc039;
  --orange: #de7800;
  --bg-indigo-grey: #f5f6ff;
  --theme-bg-color-dm: #dff5fc;
  --text-light-blue-20: #abd9fb;
  --text-light-blue: #2d9be2;
  --theme-icon-color-dm: #1c99f3;
  --text-dark-blue: #0072bc;
  --bg-medium-blue: rgba(35, 153, 229, 0.8);
  --bg-medium-blue-50: rgba(35, 153, 229, 0.5);
  --aqua: #43cdcc;
  --bg-dark-blue: #c0c1ca;
  --text-mauve: #5d5fef;
  --purple-heart: #9f3adc;
  --text-dark-grey: #828282;
  --text-grey: #656565;
  --text-green: #219653;
  --text-pink: #fcafd6;
  --primary-theme-color: #0147a3;
}
html[data-theme="penguin"][color-scheme="light"] {
  --primary-theme-color: #0147a3;
  --primary-bg-color: #ffffff;
  --primary-bg-color-light: #ccdaed;
  --primary-font-color: white;
  --secondary-font-color: #797878;
  --main-topbar-theme: #ffffff;
  --location-topbar-theme: #1f78b4;
  --theme-bg-color-dm: #dff5fc;
  --theme-icon-color-dm: #1c99f3;
}

html[data-theme="penguin"][color-scheme="dark"] {
  --primary-theme-color: #0147a3;
  --primary-bg-color: #121212;
  --primary-bg-color-light: #282d32;
  --primary-font-color: white;
  --secondary-font-color: #797878;
  --main-topbar-theme: #ffffff;
  --location-topbar-theme: #1f78b4;
  --theme-bg-color-dm: #dff5fc;
  --theme-icon-color-dm: #1c99f3;
}

.TopBar-Main {
  background-color: var(--main-topbar-theme, white);
  height: 46px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.TopBar-Location {
  background-color: var(--location-topbar-theme, #1f78b4);
  height: 50px;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
}

.queue-table-custom {
  overflow: hidden;
  border: 1px solid #2b98e1;
  border-radius: 3px;
  .rt-table {
    background-color: var(--theme-bg-color-dm, blue);
  }
  .rt-thead.-header {
    background-color: var(--theme-bg-color-dm, blue);
    color: black;
    box-shadow: none;
    border-bottom: 1px solid #2399e5;
  }
  .rt-tr.selected {
    background-color: white !important;
    color: black !important;
  }
}

.slider-ultrasound {
  display: grid;
  grid-template-columns: 35px auto 35px;
  height: 170px;
  margin-top: 20px;
  .carousel-root {
    .carousel .control-dots {
      bottom: -40px !important;
      .dot {
        width: 12px;
        height: 12px;
        background: #c4c4c4;
        opacity: 1;
        margin: 0 5px;
        &.selected {
          background: var(--text-light-blue);
        }
      }
    }
    .carousel.carousel-slider {
      overflow: unset;
    }
  }
}

.main-layout.web-dm-um {
  padding: 20px 15px 50px;
  margin-right: 10px;
  height: calc(100vh - 66px);
  overflow: auto;
  overflow-x: hidden;
  .ui.attached.tabular.menu {
    // margin-bottom: 0.3125rem;
    .item {
      // margin: 0 0.3125rem;
      background-color: #f2f2f2;
      // padding: 0.9375rem 1.5625rem;
      &.active {
        background-color: white;
        font-weight: bold;
      }
    }
  }
  .ui.bottom.attached.segment.active.tab {
    border-radius: 0.3125rem;
    border: 0;
    padding: 1.5625rem;
    min-height: calc(100vh - 125px);
  }
}

.main-layout {
  &.web-dm {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    .box-header {
      padding: 5px 40px;
      position: relative;
    }
  }

  &.web-mom {
    .box-header {
      padding: 25px 40px;
      position: relative;
    }
  }

  &.web-mom,
  &.web-dm {
    background-color: var(--theme-bg-color-dm);
    .div-header {
      display: flex;
      align-items: center;
      .icon {
        font-size: 1.875rem;
        margin: 0;
      }
      span {
        font-size: 27px;
        color: #4f4f4f;
        font-weight: bold;
        padding-left: 25px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
      .button-dm-medication {
        background-color: white;
        &.active {
          background-color: var(--text-light-blue);
        }
      }
    }
    .sub-layout {
      height: 100%;
      padding: 20px 30px 50px;
      background-color: #ffffff;
      min-height: calc(100vh - 135px);
      &.dm {
        margin: 20px -14px 0 -1px;
        padding: 20px 50px 50px 30px;
      }
    }
  }
}

.dm-date-picker {
  display: flex;
  // filter: "drop-shadow(-1px 2px 3px rgba(37, 150, 190, 0.15))",
  padding: 0 0 15px;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  & > i {
    margin: 0;
    margin-bottom: 6px;
  }
  .groupDate {
    position: relative;
    width: 194px;
    margin: 0 15px;
    & > .ui.icon.input {
      position: absolute;
      z-index: 1;
      left: 0;
      pointer-events: none;
      width: 194px;
      box-shadow: rgb(37 150 190 / 15%) -1px 2px 3px;
      input {
        text-align: center;
      }
    }
  }
  &.picker-input {
    .DayPickerInput-OverlayWrapper {
      margin-left: -2.4em;
      .DayPicker-Day,
      .DayPicker-WeekNumber {
        padding: 0.5em 0.65em;
      }
    }
  }
}

.tooltip-blood-pressure {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  // max-width: 90px;
  width: fit-content;
  border-radius: 6px;
  padding: 8px;
  // border: 1px solid rgba(133, 133, 133, 0.2);
  // box-shadow: 1px 2px 5px rgba(133, 133, 133, 0.1);
  text-align: center;
  min-width: 70px;
  opacity: 0;
  z-index: -1;
  // width: 100%;
  font-size: 0.875rem;
  transition: all 0.25s linear, opacity 0ms;
  max-width: 235px;
  pointer-events: none;
  ul {
    list-style-type: disc;
    padding-left: 18px;
    margin: 0;
  }
  &::before {
    position: absolute;
    content: "";
    z-index: 2;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5.5px solid rgba(0, 0, 0, 0.75);
    // left: 50%;
    margin-left: -5px;
    top: -5.5px;
  }
  &.top {
    @mixin arrow-bottom {
      top: 100%;
      transform: rotate(180deg);
    }
    &.right::before {
      @include arrow-bottom;
      right: 7px;
    }
    &.left::before {
      @include arrow-bottom;
      left: 12px;
    }
    &.center::before {
      @include arrow-bottom;
      left: calc(50%);
    }
  }
  &.bottom {
    &.right::before {
      right: 7px;
    }
    &.left::before {
      left: 12px;
    }
    &.center::before {
      left: calc(50%);
    }
  }
  &.right.center::before {
    top: calc(50% - 3px);
    left: -2.4px;
    transform: rotate(270deg);
  }
  &.left.center::before {
    top: calc(50% - 3px);
    right: -7px;
    transform: rotate(90deg);
  }
  &.active {
    display: block;
    opacity: 1;
    animation: fadeIn 0.25s;
    z-index: 1;
  }
}

.footer-button-settings {
  animation: visible 2s;
  display: flex;
  margin: 0px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  & > button {
    width: 18rem !important;
    margin: 0 !important;
    margin-bottom: 0.9375rem !important;
  }
  .ui.fluid[class*="left labeled"].icon.button,
  .ui.fluid[class*="right labeled"].icon.button {
    padding-left: 2.75rem !important;
  }
}

@keyframes visible {
  0%,
  40%,
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.1;
  }
  40% {
    opacity: 0.2.5;
  }
  80% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.ObstetricChild-list {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(7, 150px);
  row-gap: 10px;
}

// DM Drug
.Modal-holdMedicine {
  .three.column.row {
    z-index: 1;
  }
  .DayPickerInput-OverlayWrapper {
    margin-left: -2.4rem;
  }
}
// Child Outside medicine
.ChildMedicine-datePicker {
  @media only screen and (max-width: 425px) {
    .DayPickerInput-OverlayWrapper {
      margin-left: -4.8rem;
    }
  }
  @media only screen and (max-width: 376px) {
    .DayPickerInput-OverlayWrapper {
      margin-left: -5.5rem;
    }
  }
  @media only screen and (max-width: 361px) {
    .DayPickerInput-OverlayWrapper {
      margin-left: -6rem;
    }
  }
}

.Modal-descriptionMedicine {
  & > .header {
    background: #2399e5 !important;
    color: white !important;
  }
  .close {
    color: white !important;
  }
}
.analytics{
  .analytics-search {
    display: flex;
    justify-content: center;
    color: white;
    .search-segment {
      background: #CCDAED;
      width: 100%;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      color: white;
      .ui.search{
        color: white;
      }
      .ui.icon.input>i.icon {
        color: white;
      }
      .ui.input>input {
        width: 350px;
        border: 0px;
        color: white;
        background: transparent;
        padding: 0px ;
      }
      ::placeholder {
        color: white;
        opacity: 1; /* Firefox */
      }
    }
  }

  .PostBox-background {
    min-height: 40px;
  }

  .table-personal{
    border: 0px solid #D8D8D8;
    .ui.table{
      border-radius: 0;
    }
    .ui.sortable.table thead th {
      border: 0px;
      background: #0147A3;
      color: white;
    }

    table {
    display: flex;
    flex-flow: column;
    }

    table tbody {
      flex: 0 0 auto;
      overflow-y: auto;
    }

    table thead, table tbody tr, table tfoot {
      display: table;
      table-layout: fixed;
      width: 100%;
  }
    table tbody  {
        /* body takes all the remaining available space */
        height: calc(100vh - 320px);
        flex: 1 1 auto;
        display: block;
        overflow-y: auto;
    }
    
  }

  .hospital-dropdown{
    border-radius: 5px;
      display: flex;
      justify-content: center;
      color: #0147A3;
      font-size: 24px;
      align-items: center;
      padding: 5px 5px 5px 10px;
      .ui.dropdown>.dropdown.icon {
        display: none;
      }
      .ui.dropdown>.text {
        padding-right: 5px;
      }
      .ui.dropdown .menu {
        margin-top: 10px;
        z-index: 1000;
      }
  }

  .analytics-search-personal {
    display: flex;
    justify-content: center;
    color: white;
    .search-segment {
      background: #CCDAED;
      width: 100%;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      color: white;
      .ui.search{
        color: white;
      }
      .ui.icon.input>i.icon {
        color: white;
      }
      .ui.input>input {
        width: 270px;
        border: 0px;
        color: white;
        background: transparent;
        padding: 0px ;
      }
      ::placeholder {
        color: white;
        opacity: 1; /* Firefox */
      }
    }
  }

  .button-dropdown{
    border-radius: 5px;
    display: flex;
    justify-content: center;
    color: white;
    margin-right: 20px;
    background: #0147a3;
    padding: 5px;
    .ui.dropdown>.dropdown.icon {
      display: none;
    }
    .ui.dropdown .menu {
      margin-top: 10px;
    }
  }

  .PostPreview .PostHeader-mobilePreview{
    z-index: 999;
  }

  .button-dropdown-range-date{
    border-radius: 5px;
    display: flex;
    justify-content: center;
    color: white;
    margin-right: 20px;
    background: #0147a3;
    padding: 5px;
    .ui.dropdown>.dropdown.icon {
      display: none;
    }
    .ui.dropdown .menu {
      margin-top: 10px;
      right: 0;
      left: auto;
    }

    .date-picker-range{
      color: black;
      position: absolute;
      top: 40px;
      right: 0;
      background: white;
      padding: 10px;
      z-index: 1000;
      border-radius: 5px;
      border: 1px solid rgba(34,36,38,.15);
      box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
      .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background-color: #CCDAED !important;
        color: #0147A3;
      }
      .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover{
        background-color: #CCDAED;
        border-radius: 50% !important;
      }
      .Selectable .DayPicker-Day {
        border-radius: 0 !important;
      }
      .Selectable .DayPicker-Day--start {
        border-top-left-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
        background: #18A0FB;
      }
      .Selectable .DayPicker-Day--end {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
        background: #18A0FB;
      }
    }
  }



  .button-dropdown-light{
    border-radius: 5px;
    display: flex;
    justify-content: center;
    color: #0147A3;
    background: #CCDAED;
    padding: 5px 5px 5px 10px;
    .ui.dropdown>.dropdown.icon {
      display: none;
    }
    .ui.dropdown>.text {
      padding-right: 5px;
    }
    .ui.dropdown .menu {
      margin-top: 10px;
      z-index: 1201;
    }
  }

  .Label-LineChart {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #797878;
    padding: 2px 10px;
    margin-right: 30px;
    border-radius: 6px;
    & > div:nth-child(1) {
      width: 57px;
      height: 4px;
      border-radius: 4px;
      margin-right: 30px;
    }
  }

  .BarLabel-BarChart{
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: bold;
    border: 1px solid rgb(233 237 240);
    padding: 2px 6px;
    margin-right: 10px;
    border-radius: 6px;
    & > div:nth-child(1) {
      width: 12px;
      height: 12px;
      border-radius: 0px;
    }
  }

  .Line-DatePicker{
    color: #0147A3;
    width: 145px;
    .MuiOutlinedInput-root {
      flex-direction: row-reverse ;
      color: #0147A3;
      margin: 0px;
      padding: 0px;
      .MuiOutlinedInput-input {
        padding: 5px 0px 5px 14px !important;
        color: #0147A3;
      }
      .MuiSvgIcon-root {
        color: #0147A3;
      }
      .MuiIconButton-root{
        padding: 8px 8px 8px 0px
      }
    }
    
  }
  .box-footer-chart-dm {
    .btn-see-all{
      display: none;
    }
  }
}

.PostPreview {
  padding: 10px 5px;
  height: 100%;
  position: relative;

  .PostHeader-mobilePreview {
    height: 35px;
    width: 100%;
    background-color: var(--text-light-blue);
    border-radius: 5px;
    color: white;
    height: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: absolute;
    top: 16px;
    left: 0;
    z-index: 1101;
    width: calc(100% - 10px);
    margin-left: 5px;
  }

  .PostBox-main {
    background-color: rgb(204, 218, 237);
    border-radius: 10px;
    padding-top: 8px;
  }

  .PostBox-background {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
  }

  .PostBox-mobilePreview {
    position: relative;
    padding: 45px 15px 15px;
    height: calc(100vh - 70px);
    overflow-y: auto;
    .PostContent-mobilePreview {
      background-color: white;
      // padding-top: 10px;
      // height: 100%;
      // max-height: 620px;
      margin-bottom: 15px;
      position: relative;
      &:not(.short) {
        zoom: 87%;
        min-height: 70vh;
      }

      &.short {
        background-color: unset;
        height: auto;
        zoom: 95%;
      }
      .ContentButton-footer {
        position: absolute !important;
        width: 75%;
        & > button {
          width: 100% !important;
        }
      }
      .ui.grid.CardContent-detail {
        margin: 0px !important;
      }
    }
  }
}

.PostPreview-banners {
  width: 100%;
  height: 160px;
  background-color: #e9e9e9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  & > label {
    font-weight: bold;
    font-size: 1.125rem;
    color: #797878;
    margin-top: 5px;
  }
  & > svg {
    font-size: 2.3rem;
    color: #797878;
  }
}

.PostBox-summary {
  margin-top: 15px;
  background-color: #ccdaed;
  height: 65px;
  width: 100%;
  border-radius: 5px;
  & .PostBox-summary-items {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    height: 100%;
    & .PostBox-summary-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > label:nth-child(2) {
        color: #797878;
        margin-top: 1px;
      }
    }
  }
}

.PostButton-more {
  border: 1px solid #d3d2d7 !important;
  border-radius: 10px !important;
  min-width: 10px !important;
  height: 24px;
  padding: 3px !important;
  margin-right: 30px !important;
  & svg {
    color: #d3d2d7;
    font-size: 1.2rem;
  }
}

.PostButton-remove {
  border-radius: 5px !important;
  min-width: 10px !important;
  height: 20px;
  padding: 3px !important;
  & svg {
    color: white;
    font-size: 1rem;
  }
  background-color: #eb5757 !important;
  box-shadow: none !important;
}

.PostLabel-doughnutChart {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: bold;
  border: 1px solid rgb(233 237 240);
  padding: 2px 6px;
  margin-right: 10px;
  border-radius: 6px;
  & > div:nth-child(1) {
    width: 12px;
    height: 12px;
    border-radius: 3px;
  }
}

.PostEditor,
.PromotionEditor {
  grid-column: 2;
  border-right: 1px solid rgb(238, 238, 238);
  & .MuiOutlinedInput-input.MuiInputBase-input:not(textarea),
  & .MuiInputBase-multiline {
    padding: 12.5px 14px !important;
  }
  
  & fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #0072bc ;
  }
  & .MuiInputLabel-root.MuiInputLabel-formControl {
    line-height: 1.0375em;
    padding: 7px 6px 3px 6px;
    background-color: white;
    font-weight: bold;
    left: -18px;
    font-size: 1.25rem;
  }
  & .MuiOutlinedInput-root.MuiInputBase-root {
    margin-top: 20px;
    &.MuiAutocomplete-inputRoot {
      padding: 0;
    }
    .MuiSvgIcon-root {
      color: #0072bc;
    }
    legend {
      width: 0;
    }
  }
  input,
  textarea {
    color: #797878;
  }

  .se-dialog-image .se-dialog-tabs ._se_tab_link[data-tab-link="url"] {
    display: none;
  }

  .se-btn.se-btn-tool-format {
    width: 70px !important;
  }

  .se-toolbar.sun-editor-common {
    position: sticky;
    top: -35px;
  }

  .se-toolbar.sun-editor-common .se-btn-module .se-menu-list .se-btn {
    svg {
      color: rgba(21, 101, 192, 0.8);
    }
    &.active svg {
      color: #0147a3;
    }
  }
}

.PostList_Filter {
  & .MuiOutlinedInput-input.MuiInputBase-input:not(textarea),
  & .MuiInputBase-multiline {
    padding: 9.5px 14px ;
    color: #0147A3;
    font-weight: bold;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: #CCDAED ;
    border-radius: 10px;
  }
  & .MuiInputLabel-root.MuiInputLabel-formControl {
    line-height: 1.0375em;
    padding: 0 6px;
    background-color: white;
    font-weight: bold;
    left: -18px;
    font-size: 1.25rem;
  }
  & .MuiOutlinedInput-root.MuiInputBase-root {
    width: 100%;
    margin: 10px 5px 0 5px;

    &.MuiAutocomplete-inputRoot {
      padding: 0;
    }
    .MuiSvgIcon-root {
      color: #0147A3;
    }
    legend {
      width: 0;
    }
  }
  input,
  textarea {
    color: #797878;
  }
}

.ArticleDesc-container,
.ArticleDesc-containerEditor
  .se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable {
  .__se__solid,
  .dashed,
  .__se__dotted {
    border-color: rgba(0, 0, 0, 0.15) !important;
  }

  ul li {
    position: relative;
    display: block;
    &::before {
      content: "\2022";
      color: #0147a3;
      font-weight: bold;
      display: inline-block;
      width: 1rem;
      margin-left: -1.1865rem;
    }
  }

  span[style^="background-color: "][style$=");"],
  span[style^="color: "][style*="background-color: "][style$=");"] {
    background-image: linear-gradient(
      to top,
      transparent calc(100% - 60%),
      rgba(255, 255, 255, 1) 0px
    );
  }

  .se-component.se-image-container {
    img {
      max-width: 100%;
    }
  }
}

.ArticleDesc-containerEditor {
  .EditorLabel {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    letter-spacing: 0.00938em;
    position: relative;
    display: block;
    transform-origin: bottom left;
    transform: translate(14px, -9px) scale(0.75);
    pointer-events: auto;
    user-select: none;
    line-height: 1.0375em;
    padding: 0 6px;
    font-weight: bold;
    left: -18px;
    font-size: 1.25rem;
    top: 3px;
  }
  .se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable {
    ul li::before {
      margin-left: -1em;
    }
  }
}

.PostContent-mobilePreview .ArticleDesc-container {
  ul li::before {
    margin-left: -1.1865em;
  }
}

.SegmentEditor {
  border-right: 1px solid rgb(238, 238, 238);
  & .MuiOutlinedInput-input.MuiInputBase-input:not(textarea) {
    padding: 12.5px 14px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: #0072bc !important;
  }
  & .MuiInputLabel-root.MuiInputLabel-formControl {
    line-height: 1.0375em;
    padding: 0 6px;
    background-color: white;
    font-weight: bold;
  }
  & .MuiOutlinedInput-root.MuiInputBase-root.MuiAutocomplete-inputRoot {
    padding: 0;
  }
  input,
  textarea {
    color: #797878;
  }
}

.SegmentPreview {
  padding: 20px;
  margin-left: 10px;
  height: 100%;
  border-radius: 10px;
  background-color: #0147a3;
  & .ui.header {
    color: white;
  }
  .centerText {
    text-align: center;
    padding: 10px;
  }
}

.SegmentChart {
  &.ui.progress {
    background: none;
    & .bar {
      background: var(--aqua);
      & .progress {
        right: -45px;
        color: grey;
      }
    }
  }
}

.PostSearchBox-root {
  .MuiSvgIcon-root {
    color: #0072bc;
  }
}

.PostSortableList-root {
  user-select: none;

  border: 1px solid #0072bc;
  border-radius: 5px;
  min-height: 214px;
  list-style: none;
  margin: 0px;
  padding: 0;

  li {
    list-style-image: none;
    border-bottom: 1px solid #0072bc;
    padding: 4px;
    color: #666;
    cursor: move;
    height: 50px;
    display: grid;
    grid-template-columns: 20% 74% 6%;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding-left: 8px;
    }

    // &.over {
    //   border-top: 2px solid var(--text-light-blue);
    //   transform: scale(1.1, 1.1);
    // }

    .PostButton-remove {
      width: 20px;
    }
  }

  & .PostSortableList-items {
    border-bottom: 1px solid #0072bc;
  }

  & .MuiList-root.MuiList-padding {
    padding: 0;
  }

  .PostButton-arrow {
    border-radius: 5px !important;
    min-width: 10px !important;
    height: 17px;
    padding: 3px !important;
    width: 18px;
    & svg {
      color: #9f9f9f;
      font-size: 1rem;
      position: absolute;
    }
    box-shadow: none !important;
    border: 0 !important;
    &.top svg {
      transform: rotate(90deg);
      top: 5px;
    }
    &.bottom svg {
      transform: rotate(90deg);
      top: 2px;
    }
  }
}

.PostButton-browse {
  height: 45.5px;
  min-width: 100px;
  text-transform: unset;
}

.SegmentTable-custom {
  overflow: hidden;
  border: 1px solid #d8d8d8;
  border-radius: 3px;

  .rt-thead .rt-th {
    border-right: 0;
  }
  .rt-th {
    border: 0;
  }
  .rt-thead.-header {
    background-color: var(--text-light-blue);
    color: white;
    box-shadow: none;
    & .rt-tr {
      text-align: left;
      & > div {
        padding: 10px 0 10px 30px;
      }
    }
  }
  .rt-tr:not(.blueSelectedRow):not(.selected):hover .rt-td {
    background: transparent !important;
  }
  .rt-tbody .rt-tr-group {
    border-bottom: 0.5px solid #d8d8d8;
    cursor: pointer;
    & .rt-tr.selected {
      background-color: #ccdaed !important;
      color: #000000 !important;
    }
  }
  .rt-th,
  .rt-td {
    padding: 10px 8px;
  }
}

.DmDropdown-custom {
  & .visible.menu.transition {
    max-height: 220px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: block;
    }
  }
}

.MainDm-tabPath,
.MainMom-tabPath {
  .MainDm-menuIcon {
    color: #797878;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3px 0;
    text-align: center;
    font-size: 13px;
    img {
      width: 24px;
      height: 24px;
      margin-bottom: 5px;
      object-fit: contain;
    }
    label {
      line-height: 16px;
    }
  }
  & > div > div > div[style^="background-color: rgb(214, 236, 243);"] {
    background-color: rgba(32, 131, 193, 0.75) !important;
    .MainDm-menuIcon {
      color: white;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}


/*  Preferred */

.PreferredPreview {
  padding: 10px 5px;
  height: 100%;
  position: relative;

  .PreferredHeader-mobilePreview {
    height: 35px;
    width: 100%;
    background-color: var(--text-light-blue);
    border-radius: 5px;
    color: white;
    height: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: absolute;
    top: 16px;
    left: 0;
    z-index: 1101;
    width: calc(100% - 10px);
    margin-left: 5px;
  }

  .PreferredBox-main {
    background-color: rgb(204, 218, 237);
    border-radius: 10px;
    padding-top: 8px;
  }

  .PreferredBox-mobilePreview {
    position: relative;
    padding: 45px 15px 15px;
    height: calc(100vh - 70px);
    overflow-y: auto;
    .PreferredContent-mobilePreview {
      background-color: white;
      // padding-top: 10px;
      // height: 100%;
      // max-height: 620px;
      margin-bottom: 15px;
      position: relative;
      &:not(.short) {
        zoom: 87%;
        min-height: 70vh;
      }

      &.short {
        background-color: unset;
        height: auto;
        zoom: 95%;
      }
      .ContentButton-footer {
        position: absolute !important;
        width: 75%;
        & > button {
          width: 100% !important;
        }
      }
      .ui.grid.CardContent-detail {
        margin: 0px !important;
      }
    }
  }
}

