.my-coupon {
  .ui input {
    border-radius: 10px;
  }
  .ui.button {
    &.rounded {
      border-radius: 15px;
      justify-content: center;
    }
    // &.primary {
    //   background-color: var(--primary-theme-color);
    //   color: white;
    // }
  }

  // Font
  .font-bold {
    color: #393939;
    font-weight: bold;
  }
  // Color
  .grey {
    color: #797878;
  }
  .teal {
    background-color: #32cdcd;
  }

  .makeStyles-button-2 {
    border-radius: 10px !important;
  }

  .tab-coupon {
    .ui.button {
      color: #797878;
      background-color: white;
      box-shadow: 0px 0px 6px 1px rgba(227, 239, 255, 0.5);
      margin-right: 15px;
      width: 105px;
      justify-content: center;
      height: 36px;
      &.active {
        background-color: #ccdaed;
        color: #0147a3;
      }
    }
  }

  .close-icon {
    position: absolute;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 5px;
    border-radius: 5px 0 0 5px;
    top: 14px;
    cursor: pointer;
  }

  .card-frame {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 1px rgba(227, 239, 255, 0.5);
    overflow: hidden;
    position: relative;
  }

  .card-group {
    min-height: 148px;
    margin-bottom: 12px;
    margin-top: 12px;
    display: grid;
    grid-template-columns: 18px auto;
    .card-tag-name {
      width: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 !important;
      label {
        text-transform: uppercase;
        color: white;
        transform: rotate(-90deg);
      }
    }
    .ribbon {
      position: absolute;
      top: 6px;
      right: 0;
      background-color: #c4c4c4;
      padding: 3px 20px 2px 10px;
      font-weight: bold;
      color: #393939 !important;
      text-transform: uppercase;
    }
  }

  .card-image {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-left: 0px;
  }

  &.modal-card-group {
    display: flex !important;
    justify-content: center;
    .ui.card {
      border-radius: 5px;
      box-shadow: 0px 0px 8px 1px rgba(131, 133, 135, 0.2);
      overflow: hidden;
      position: relative;
      width: 100%;
      border-radius: 10px;
      font-size: 17px;
      width: 95%;
      .header {
        display: flex;
        justify-content: space-between;
        color: white;
        font-weight: bold;
        padding: 20px 15px;
      }
    }
  }

  .inactive {
    user-select: none;
    .font-bold {
      color: #c4c4c4;
    }
    div {
      color: #e9e9e9;
    }
    .card-tag-name,
    .header,
    .card-image {
      background-color: #c4c4c4;
      div {
        color: white;
      }
    }
    .redeem {
      background-color: #e9e9e9 !important;
    }
    .qr-code-icon {
      filter: contrast(0.5);
      opacity: 0.5;
    }
  }
}

.ui.page.modals.dimmer.transition.visible.active {
  justify-content: center;
}

.label-required::after {
  content: "*";
  color: #dc1213;
  margin-left: 2px;
}
