:root {
  --success: #00c300;
  --warning: #edc039;
  --orange: #de7800;
  --text-light-blue: #2d9be2;
  --text-dark-blue: #0072bc;
  --text-grey: #656565;
  --text-dark-grey: #4e4e4e;
  --theme-bg-color-dm: #dff5fc;
  --bg-blue: #00d0cc;
  --blue-bdms-color: #0147a3;
}

html[data-theme="penguin"][color-scheme="light"] {
  --primary-theme-color: #0147a3;
  --primary-bg-color: #ffffff;
  --primary-bg-color-light: #ccdaed;
  --light-blue-bdms-color: rgb(149, 197, 228);
  --secondary-font-color: #797878;
  --main-topbar-theme: #ffffff;
  --location-topbar-theme: #1f78b4;
  --super-dark-gray: #393939;
  --skeleton-bg-color: rgba(255, 255, 255, 0.13);
  --light-red-color: #ffadad;
  --light-red-half-color: #f8e1e3;
  --violet-color: #6d74e6;
  --card-bg-color-light: rgba(255, 255, 255, 0.85);
}

html[data-theme="penguin"][color-scheme="dark"] {
  --primary-theme-color: #0147a3;
  --primary-bg-color: #121212;
  --primary-bg-color-light: #282d32;
  --secondary-font-color: #797878;
  --light-blue-bdms-color: rgb(149, 197, 228);
  --main-topbar-theme: #ffffff;
  --location-topbar-theme: #1f78b4;
  --super-dark-gray: #393939;
  --skeleton-bg-color: rgba(255, 255, 255, 0.13);
  --light-red-color: #ffadad;
  --light-red-half-color: #f8e1e3;
  --violet-color: #6d74e6;
  --card-bg-color-light: rgba(255, 255, 255, 0.85);
}

.default-background {
  background: linear-gradient(
      0deg,
      rgba(1, 71, 163, 0.2) 9.58%,
      rgba(1, 71, 163, 0) 100%
    ),
    white;
  min-height: 100%;
  height: fit-content;
}

/* Tab */

.appointment-tab-custom {
  width: 100%;
  background-color: transparent;
  z-index: 1;
  max-width: 100vw;
  text-align: center;
  .ui.pointing.secondary.menu {
    border-bottom: 0;
    margin-bottom: 2.5px;
    font-size: 16px;
    &:hover,
    .active.item {
      border-color: #da0000;
      border-bottom-width: 3.5px;
      color: var(--location-topbar-theme);
    }
    .item {
      border-color: rgb(34 36 38 / 10%);
      border-bottom-width: 3px;
      color: #9e9e9e;
      width: 100%;
    }
  }
  &.--small .ui.pointing.secondary.menu {
    min-height: 0rem;
    &:hover,
    .active.item {
      border-bottom-width: 2.5px;
    }
    .item {
      border-bottom-width: 2px;
      padding: 0.75rem;
    }
  }
  &.--two .ui.pointing.secondary.menu .item {
    width: 50%;
    max-width: 50%;
    justify-content: center;
  }
  &.--three .ui.pointing.secondary.menu {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    & .item {
      font-size: 1rem;
      justify-content: center;
      padding: 0.75rem 0.65rem;
    }
  }
  &.--red .ui.pointing.secondary.menu .active.item.item {
    border-color: var(--primary-theme-color);
    color: var(--primary-theme-color);
  }
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.75);
}

// Global
.react-swipeable-view-container > .app-view {
  height: calc(100vh - 160px);
  overflow: auto;
  &.--h90 {
    height: calc(100vh - 90px);
    overflow: auto !important;
  }
}

.MuiSkeleton-root.MuiSkeleton-pulse {
  background-color: rgba(0, 0, 0, 0.08);
}

@mixin shape-polygon {
  content: "";
  position: fixed;
  width: 46.5vw;
  height: 39.5vh;
  background: #0147a3;
  opacity: 0.05;
}

.bg-regist-incomplete {
  background: linear-gradient(
      0deg,
      rgba(1, 71, 163, 0.2) -7.92%,
      rgba(1, 71, 163, 0) 85.6%
    ),
    #ffffff;
  min-height: 100%;
  height: fit-content;
  &:before {
    @include shape-polygon;
    left: 0;
    top: 0;
    clip-path: polygon(100% 0, 0% 100%, 0 0);
  }
  &:after {
    @include shape-polygon;
    right: 0;
    bottom: 0;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  }
}

.diarule-search-make-appointment {
  // Diag rule search
  .diag-rule-search {
    height: calc(100vh - 60px);
    overflow-y: auto;
    padding: 0 5px;
    .title {
      color: var(--blue-bdms-color);
      font-weight: bold;
    }
    .ui.fluid.left.icon.input {
      padding: 5px;
      input {
        border-radius: 25px;
      }
    }
    .ui.celled.list {
      & > .item:first-child {
        .wide.column {
          width: 50% !important;
          p {
            color: grey;
          }
        }
      }
      & > .item,
      & > .list {
        border: 0;
        border-bottom: 1px solid #e9e9e9;
        box-shadow: 0px 0px 6px 1px rgb(227 239 255 / 50%);
        background: white;
        border-radius: 10px;
        margin-bottom: 8px;
      }
    }
    .item.diag-rule-list-title {
      border-radius: 0 !important;
      box-shadow: none !important;
      background-color: transparent !important;
    }
    .item.diag-rule-list-item {
      padding: 18.5px 10px;
      &:first-child,
      &:last-child {
        padding: 17.5px 10px;
      }
      .ui.tiny.label {
        line-height: 14px;
      }
      .five.wide.column {
        padding-left: 0;
        padding-right: 0;
      }
      .eleven.wide.column {
        color: var(--blue-bdms-color);
      }
    }
    .ui.celled.selection.middle.aligned.list {
      margin: 0;
      padding: 0 10px 25px;
    }
  }
}

// Form
.diaform-make-appointment {
  .App-main {
    // background-color: white;
    height: calc(100vh - 56px);
    overflow-y: auto;
    .App-content {
      .dimmable {
        .ui.label {
          padding: 15px;
          font-size: 1rem;
          margin: 10px 5px 15px;
        }
        .ui.button {
          border-radius: 5px;
        }
        .ui.input input {
          border-radius: 8px;
        }
        .ui.checkbox {
          margin-bottom: 15px !important;
        }
        .ui.green.button {
          background-color: var(--blue-bdms-color);
          border-radius: 10px;
          width: 50%;
        }
        .ui.segment.section-row {
          box-shadow: 0px 0px 6px 1px rgb(227 239 255 / 50%);
          border: 0;
          margin-bottom: 5px !important;
        }
        .ui.inverted.dimmer {
          height: calc(100vh - 65px);
        }
      }
    }
    // Checkbox
    .ui.checkbox {
      display: flex;
      align-items: center;
      label {
        padding-left: 2.2em;
        padding-top: 2px;
        &::before {
          border-width: 2px;
          border-color: var(--blue-bdms-color) !important;
        }
        &::after {
          // background-color: #90419a;
          color: var(--blue-bdms-color) !important;
        }
        &::before,
        &::after {
          width: 20px;
          height: 20px;
          font-size: 15px;
          padding-top: 1px;
        }
      }
      &.checked {
        label:before {
          border-color: var(--blue-bdms-color) !important;
        }
        &.radio {
          label::after {
            background-color: var(--blue-bdms-color) !important;
          }
        }
      }
    }
  }
}

.body-mob-appointment {
  .ui.page.modals.dimmer.transition.visible.active {
    z-index: 1100;
  }
  .ui.modal.transition.visible.active {
    border-radius: 15px;
    overflow: hidden;
    width: 85.5%;
    .ui.inverted.segment.modHeader,
    .header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      text-align: center;
    }
    .ui.error.visible.message {
      margin: 0 0 5px;
    }
    .modContent {
      padding-top: 1em;
    }
    .ui.button {
      margin-top: 0 !important;
      height: 40.2px;
      border-radius: 10px;
      min-width: 40%;
      &.approveButton {
        margin-top: 13px !important;
      }
    }
  }
}

.ui.icon.input.location-input-search {
  width: 100%;
  input {
    padding-left: 42px;
    border-radius: 25px;
    font-size: 17px;
    // box-shadow: 0px 0px 8px 1px rgba(131, 133, 135, 0.2);
  }
  .ui.circular.button {
    background-color: rgb(1, 71, 163);
    padding: 0px 9.98px;
    position: absolute;
    right: 2px;
    width: 37px;
    height: 37px;
    top: 4px;
  }
}

.sb-search-doctor {
  border-radius: 10px;
  background-color: var(--primary-bg-color);
  width: 100%;
  z-index: 3;
  padding: 15px 0 10px 0;
  & ul,
  & li {
    list-style-type: none;
  }
  & ul {
    padding: 0;
    margin: 0;
    max-height: 82.5vh;
    overflow: auto;
  }
  & li {
    display: grid;
    grid-template-columns: 70px auto;
    margin-bottom: 2px;
  }
}

.sb-end-of-list {
  text-align: center;
  font-size: 16px;
  padding: 10px 0;
  color: var(--secondary-font-color);
}

.sb-div-floor {
  height: 100vh;
  position: absolute;
  background: transparent;
  width: 100vw;
  left: -4vw;
  z-index: 1;
}

$CARD_WIDTH: 70%;

.dialog-queue-info {
  & .MuiDialog-paper {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    margin: 0;

    & .sub-tile {
      color: rgba(121, 120, 120, 1);
    }

    & .divider {
      border-bottom: 1px solid rgba(196, 196, 196, 1);
      width: 100%;
      border-bottom-style: dashed;
      position: relative;
      z-index: 2;
    }

    & .box-queue {
      z-index: 1;
      width: $CARD_WIDTH;
      position: relative;
      &:after {
        top: -4%;
        width: calc(100% - 25px);
        height: 10px;
        left: 12.5px;
        content: " ";
        background-color: white;
        position: absolute;
      }
      & .queue {
        position: relative;
        text-align: left;
        border-radius: 8px;
        width: 100%;
        padding: 1.25rem 1.5rem 1.75rem;
        background: radial-gradient(
              circle at top left,
              transparent 10px,
              white 0
            )
            top left,
          radial-gradient(circle at top right, transparent 10px, white 0) top
            right,
          radial-gradient(circle at bottom left, transparent 0, white 0) bottom
            left,
          radial-gradient(circle at bottom right, transparent 0, white 0) bottom
            right;
        background-size: 51% 51%;
        background-repeat: no-repeat;
        & > .number {
          font-size: 2.625rem;
          font-weight: bold;
          color: var(--blue-bdms-color);
          text-align: center;
          background-color: rgba(236, 244, 255, 1);
          padding: 0.65rem 0 1.35rem;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 78px;
          & label {
            color: rgba(57, 57, 57, 1);
            font-size: 1.125rem;
            font-weight: normal;
          }
          & > div {
            margin-top: 0.75rem;
          }
        }
      }
    }

    & .content-inner {
      position: relative;
      padding: 4rem 0 0.5rem;
      background: radial-gradient(circle at top left, transparent 0, white 0)
          top left,
        radial-gradient(circle at top right, transparent 0, white 0) top right,
        radial-gradient(circle at bottom left, transparent 10px, white 0) bottom
          left,
        radial-gradient(circle at bottom right, transparent 10px, white 0)
          bottom right;
      background-size: 51% 51%;
      background-repeat: no-repeat;
      width: $CARD_WIDTH;
      z-index: 1;
      border-radius: 8px;
    }

    & .MuiButton-root.close {
      &.MuiButton-root {
        position: absolute;
        top: 10px;
        right: -5px;
        color: rgba(44, 44, 44, 1);
        & svg {
          font-size: 21px;
        }
      }
    }

    & .box-en {
      font-size: 1rem;
      color: var(--blue-bdms-color);
      text-align: center;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }
}

.carousel-container {
  & .carousel {
    padding-bottom: 1.5rem;
    & .control-dots {
      margin-bottom: -0.15rem;
      & .dot {
        width: 8px;
        height: 8px;
        background: white;
        opacity: 1;
        box-shadow: none;
        margin: 0 5px;
      }
      & .dot.selected {
        background: var(--blue-bdms-color);
      }
    }
  }
}

.carousel-container-hide {
  & .carousel {
    padding-bottom: 1.5rem;
    & .control-dots {
      display: none;
    }
  }
}
