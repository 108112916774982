.main-screen {
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .verify-number {
    display: flex;
    margin: 30px 15px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background: var(--transparent-transparent-blue-5, rgba(1, 71, 163, 0.05));
    color: var(--primary-theme-color);
    font-weight: bold;
    font-size: 20px;
    & .ui.input > input {
      background: transparent;
      border: 0;
      width: 100%;
      text-align: center;
    }
  }

  .verify-number-active {
    display: flex;
    margin: 30px 15px;
    padding: 20px;
    align-items: center;
    border-radius: 10px;
    background: var(--transparent-transparent-blue-5, rgba(1, 71, 163, 0.05));
    color: var(--primary-theme-color);
    font-weight: bold;
    font-size: 20px;
    & .ui.input > input {
      background: transparent;
      border: 0;
      width: 100%;
      text-align: center;
    }

    & .ui.checkbox input:checked ~ label:before {
      background: #ccdaed;
      border: 0px;
    }

    & .ui.radio.checkbox input:checked ~ label:after {
      background: var(--primary-theme-color);
    }
  }

  .info-forgot-verify {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 10px;
    margin: 30px 0 0 0;
  }

  .not-match-no-number {
    display: flex;
    gap: 10px;
    border: 1px solid #ffeeb9;
    border-radius: 8px;
    padding: 12px;
    margin: 10px 0px;
    color: #f39c12;
    background: #fff8e3;
    align-items: center;
  }

  .list-radio-phone {
    display: flex;
    align-items: center;
    padding: 16px;
    margin: 15px 0px;
    width: 100%;
    border: 1px solid #79787826;
    border-radius: 8px;
    font-weight: bold;

    & .ui.checkbox {
      margin-right: 10px;
    }
  }
  .list-radio-phone-active {
    display: flex;
    align-items: center;
    padding: 16px;
    margin: 15px 0px;
    width: 100%;
    border: 1px solid var(--primary-theme-color);
    border-radius: 8px;
    font-weight: bold;

    & .ui.checkbox {
      margin-right: 10px;
    }

    & .ui.checkbox input:checked ~ label:before {
      background: #ccdaed;
      border: 0px;
    }

    & .ui.radio.checkbox input:checked ~ label:after {
      background: var(--primary-theme-color);
    }
  }

  .type-register {
    display: flex;
    padding: 10px;
    border-radius: 30px;
    width: 100%;
    background-color: #ecf4ff;
    margin: 24px 0px;

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--primary-theme-color);
      padding: 10px 0px;
      border-radius: 30px;

      &.active {
        color: white;
        font-weight: bold;
        background: var(--primary-theme-color);
      }
    }
  }

  .type-id {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    color: #9ab4bd;
    margin: 10px 0px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 8px;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 10px 6px;
      border-radius: 8px;
      border: 1px solid #e9e9e9;
      .ui.image {
        width: 32px;
        margin-bottom: 12px;
      }

      &.active {
        color: var(--primary-theme-color);
        background: #ecf4ff;
        border: 1px solid var(--primary-theme-color);
      }
    }
  }

  .content-filed-input {
    padding: 5px;
    display: flex;
    flex-direction: column;

    .ui.label {
      background: transparent;
      margin-bottom: 8px;
      margin: 10px 0px;
      padding: 0px;
      font-size: 14px;
      color: #393939;
    }

    .ui.input {
      width: 100%;
    }
    .ui.input > input {
      border-radius: 8px;
      padding: 0.9rem 1rem;
    }

    & .error-input {
      & > input {
        border: 1px solid red;
      }
    }

    div:nth-child(3) {
      display: none;
    }

    & .form-control {
      width: 100%;
    }

    & > div {
      &.error {
        display: flex;
        margin-top: 5px;
        color: red;
      }
      &.normal {
        display: none;
      }
    }
  }

  .text-backtologin {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #797878;
    div:nth-child(2) {
      color: var(--primary-theme-color);
      margin-left: 5px;
    }
  }

  .content-filed-input-phone {
    padding: 5px;
    display: flex;
    flex-direction: column;

    .ui.label {
      background: transparent;
      margin-bottom: 8px;
      margin: 10px 0px;
      padding: 0px;
      font-size: 14px;
      color: #393939;
    }

    .ui.input {
      width: 100%;
    }
    .ui.input > input {
      border-radius: 8px;
    }

    & .error-input {
      & > input {
        border: 1px solid red;
      }
    }

    & .form-control {
      width: 100%;
      height: 38px;
    }

    & .flag-dropdown {
      padding: 2px;
      background: transparent;
      border: 0px;
    }
    & .flag-dropdown.open {
      padding: 2px;
      background: transparent;
      border: 0px;
      padding: 2px;
    }

    & .country-list {
      width: calc(100vw - 52px);
    }

    & > div {
      &.error {
        display: flex;
        margin-top: 5px;
        color: red;
      }
      &.normal {
        display: none;
      }
    }
  }

  .div-center {
    display: flex;
    justify-content: center;
    color: var(--primary-theme-color);
  }

  .list-rule-password {
    color: #797878;
    margin: 24px 0px;
  }

  .otpContainer {
    margin: 10px auto;
  }

  .otpInput {
    width: 45px;
    height: 60px;
    margin: 0 5px;
    font-size: 2rem;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #c4c4c4;
    color: #393939;
    background: transparent;
    &:focus {
      border-color: var(--primary-theme-color);
      transition: border-color 0.3s ease-in-out;
      outline: 0;
    }
  }

  .otp-ref-code {
    font-size: 14px;
    color: #797878;
    display: flex;
    justify-content: center;
    margin: 20px;
  }

  .otp-timeout {
    font-size: 20px;
    margin: 10px 0 25px;
    font-size: 14px;
    color: #393939;
    display: flex;
    justify-content: center;
    margin: 20px;
  }

  ////////////// EditProfile //////////////////

  .form-margin {
    margin: 5px 18px;
    .ui.input input {
      padding: 0.9rem 1rem;
    }

    textarea {
      resize: none;
      border-radius: 8px !important;
      background: transparent !important;
    }

    label {
      color: lrgba(57, 57, 57, 1);
    }

    .ui.selection.dropdown {
      padding: 1rem 1rem;
      & .icon {
        top: 1.1rem;
        right: -0.05rem;
        font-size: 1rem;
        width: 2.67142857em;
        opacity: 0.5;
        position: absolute;
        &.calendar {
          font-size: 1.25rem;
          top: 1rem;
          right: -0.35rem;
          opacity: 0.5 !important;
        }
      }
    }
  }

  .ui.form .field.field input:-webkit-autofill {
    background: #e4e4e4;
  }

  .ui.form .field > label {
    color: #393939;
    font-size: 14px;
  }

  .ui.form .field > .selection.dropdown {
    background: transparent;
    border-radius: 8px;
    border: 1px solid #e4e4e4;
  }
  .ui.form .field .ui.input input,
  .ui.form .fields .field .ui.input input {
    background: transparent;
    border-radius: 8px;
    border: 1px solid #e4e4e4;
    &:disabled {
      background: #f5f5f5;
      border: 1px solid #e2e2e2;
    }
  }

  .ui.form .disabled.field {
    opacity: 1;
  }

  .ui.form .field.disabled > label {
    opacity: 1;
  }

  .ui.disabled.input {
    opacity: 1;
  }

  .date-picker {
    border: 0px;
    width: 100%;
    .MuiOutlinedInput-root {
      flex-direction: row-reverse;
      margin: 0px;
      padding: 0px;
      width: 100%;
      border: 0px;
      .MuiOutlinedInput-input {
        padding: 5px 0px 5px 14px !important;
        background: transparent;
        border-radius: 50px;
        border: 0px;
        width: 100%;
      }
      .MuiOutlinedInput-notchedOutline {
        border: 0px;
      }
    }
  }

  ////////////// EditProfile //////////////////

  .edit-header-section {
    background: #ecf4ff;
    padding: 0.625rem 18px;
    align-items: center;
    gap: 10px;
    display: flex;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #393939;
    margin-top: 1rem;
  }
}

////////////// lobby //////////////////
.main-login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .login-head {
    display: flex;
    justify-content: space-between;
    padding: 30px 20px 10px 30px;
    width: 100%;

    .logo {
      display: flex;
      justify-content: center;
      color: var(--primary-theme-color);
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      align-items: center;
      gap: 10px;
    }
    .skip {
      border-radius: 8px;
      background: white;
      padding: 10px 12px;
      display: flex;
      justify-content: center;
      color: #393939;
      text-align: center;
      font-size: 12px;
      box-shadow: 0px 4px 8px 0px rgba(44, 44, 44, 0.08);
      gap: 10px;
    }
  }
  .login-image {
    min-height: calc(100vh - 370px);
  }
  .login-selected {
    position: absolute;
    bottom: 0px;
    border-radius: 18px 18px 0px 0px;
    background: white;
    display: flex;
    width: 100%;
    padding: 30px;
    flex-direction: column;
    align-items: center;
    .login-selected-head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: #393939;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
      .select-language {
        display: flex;
        font-size: 12px;
        gap: 10px;
        align-items: center;
      }
    }
  }
}

.modal-langue {
  margin: 20px 0px;
  .modal-title {
    margin: 16px 0px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    & .icon {
      position: absolute;
      right: 0px;
      margin-right: 10px;
    }
  }
  .modal-content {
    display: flex;
    margin: 20px;
    .list-item-langue {
      width: 100%;
      display: flex;
      align-items: center;
      .ui.image {
        width: 28px;
        margin-right: 5px;
      }
    }
    div:nth-child(1) {
      display: flex;
      align-items: center;
    }
  }
}

.highlight {
  color: var(--primary-theme-color);
  margin: 0px 5px;
  display: flex;
}

.bplus-buttom {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
  & > div {
    min-width: 70%;
    width: 100%;
    display: flex;
    min-height: 45px;
    justify-content: center;
    align-items: center;
    background: var(--primary-theme-color);
    color: white;
    border-radius: 8px;
    font-size: 16px;

    & .disable {
      opacity: 0.4;
    }

    & .backward {
      background: transparent;
      color: var(--primary-theme-color);
      border: 1px solid var(--primary-theme-color);
    }
  }

  & .ui.button {
    min-width: 70%;
    display: flex;
    min-height: 45px;
    justify-content: center;
    align-items: center;
    background: var(--primary-theme-color);
    color: white;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
  }

  //////// ****** backward button style set in code ******** /////////
  // .backward {
  //     background:  transparent;
  //     color: var(--primary-theme-color);
  //     border: 1px solid  var(--primary-theme-color);
  // }
}

.devMode {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background: gray;
  border-radius: 10px;
  opacity: 0.7;
}

.title-text-blue {
  color: var(--primary-theme-color);
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 29px;
  margin: 1rem 0px 10px;
}

.text-blue-bold {
  color: var(--primary-theme-color);
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 29px;
  margin: 1rem 0px 10px;
}

.text-blue {
  color: var(--primary-theme-color);
  font-size: 16px;
  font-style: normal;
  line-height: 29px;
  margin: 1rem 0px 10px;
}

.title-text-black {
  color: #393939;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
}

.text-black-bold {
  color: #393939;
  font-size: 18px;
  font-style: normal;
  line-height: 22px;
  font-weight: bold;
}

.text-gray {
  color: #797878;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
}

.div-center-bottom {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 30px;
  flex: 1;
}

.dialog-policy {
  .MuiDialog-paper {
    margin: 30px 0px 0px;
  }
  .MuiDialog-paper {
    max-width: 100%;
  }
}
