@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat');
.MobHistory {
  .screen {
    background: #F3F5F9;
    min-height: max-content;
    padding-bottom: 2rem;
    position: relative;
  }

  .header_inner {
    position: relative;
    padding: 15px 15px 20px;
    background: linear-gradient(90deg, #0147A3 0%, #0B87CD 100%);
    margin-top: -50px;
    padding-top: 50px;
    height: 270px;
  }

  .title-text-white {
    color: white;
    font-size: 24px;
    font-family: Montserrat, Noto Sans Thai;
    font-weight: bold;
    line-height: 29px;
    margin: 16px 0 24px;
  }

  .backgroundImage {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: auto;
    z-index: 1001;
    padding-top: -50px;
  }

  .foregroundContent {
    position: relative;
    z-index: 1;
  }

  .content {
    position: absolute;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-top: -16px;
    background: white;
    width: 100%;
    padding: 22px 16px;
    z-index: 1001;
  }

  .card {
    margin: -150px 20px 20px;
    left: 0;
    right: 0;
    // width: 85%;
    /* padding: 1rem;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 2px 3px #c4c4c4; */
    margin-bottom: 1rem;
  }

  .title {
    font-size: 20px;
    font-family: "Montserrat, Noto Sans Thai", sans-serif;
    line-height: 24px;
    font-weight: bold;
    margin: 1rem 0px 0.25rem 0px;
  }

  .emptyState {
    text-align: center;
    padding: 2rem 1rem;
  }

  .emptyImage {
    width: 150px;
    margin-bottom: 1rem;
  }
}