:root {
  --primary-theme-color: rgba(93, 188, 210);
  --background-theme-color: white;
  --blue-bdms-color: #0147a3;
  --light-blue-bdms-color: rgb(149, 197, 228);
  --super-dark-gray: #393939;
}

html[data-theme="penguin"][color-scheme="light"] {
  --primary-theme-color: #0147a3;
  --background-theme-color: white;
  --blue-bdms-color: #0147a3;
  --light-blue-bdms-color: rgb(149, 197, 228);
  --super-dark-gray: #393939;
  --card-bg-color-light: rgba(255, 255, 255, 0.85);
}

html[data-theme="penguin"][color-scheme="dark"] {
  --primary-theme-color: rgba(93, 188, 210);
  --background-theme-color: black;
  --blue-bdms-color: #0147a3;
  --light-blue-bdms-color: rgb(149, 197, 228);
  --super-dark-gray: #393939;
  --card-bg-color-light: rgba(255, 255, 255, 0.85);
}

.FeedDetail {
  padding-bottom: 25px;
  background: #F1F5FA;
  .FixedMenu {
    display: flex;
    justify-content: center;
    height: 70px;
    align-items: center;
  }
  .CollectionMenu {
    display: flex;
    justify-content: center;
    height: 70px;
    align-items: center;
  }
  .other-service-title {
    padding: 15px 0px 2px 15px;
    font-size: 16px;
    font-weight: 600;
    color: #393939;
  }
  .other-service-menu {
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
    // height: 100px;
    align-items: center;
    background-color: transparent;
    // width: 100%;
    // overflow-y: hidden;
    // overflow-x: auto;
    .other-service-item {
      margin: 10px 0 0 0;
      width: 80px;
      // height: 100px;
      min-width: 80px;
      /* text-align: center; */
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      .other-service-icon-outer {
        width: 48px;
        height: 48px;
        margin-bottom: 4px;
        background: var(--light-blue-bdms-color);
        -moz-border-radius: 24px;
        -webkit-border-radius: 24px;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .other-service-icon {
        width: 50px;
        height: 50px;
        // background: var(--blue-bdms-color);
        // -moz-border-radius: 18px;
        // -webkit-border-radius: 18px;
        // border-radius: 18px;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 4px 8px 0px rgba(44, 44, 44, 0.08);
        border-radius: 8px;
        position: relative;
      }
      .other-service-img {
        // width: 20px;
        // height: 20px;
        width: 30px;
        height: 30px;
      }
      .other-service-text {
        color: darkgray;
        text-align: center;
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 80%;
        margin-top: 10px;
      }

      &.badge .other-service-icon::before {
        content: " ";
        width: 10px;
        height: 10px;
        border-radius: 500rem;
        background-color: #d32f2f;
        right: -2px;
        top: -1px;
        position: absolute;
      }
    }
  }
  .today-event {
    width: 100%;
    height: 500px;
  }
}

.default-background-none-before {
  background: linear-gradient(
      0deg,
      rgba(1, 71, 163, 0.2) -7.92%,
      rgba(1, 71, 163, 0) 100%
    ),
    #ffffff;
  min-height: 100%;
  height: fit-content;
}

.default-background {
  background: linear-gradient(
      0deg,
      rgba(1, 71, 163, 0.2) -7.92%,
      rgba(1, 71, 163, 0) 100%
    ),
    #ffffff;
  min-height: 100%;
  height: fit-content;
}

.default-background::before {
  content: "*";
  position: fixed;
  width: 46.5vw;
  height: 40vh;
  right: 0;
  bottom: 0;
  background: #0147a3;
  opacity: 0.05;
  -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

.Feed-menuBar {
  // margin-top: 10px;
  .MuiTabPanel-root {
    padding: 0 0px;
  }
  .MuiBox-root {
    border-bottom: 0;
    padding: 0 0px;
  }
  .MuiTabs-indicator {
    height: 0;
  }
  .MuiButtonBase-root.MuiTab-root {
    text-transform: unset;
    font-size: 18px;
    font-weight: bold;
    color: #9f9f9f;
    &.Mui-selected {
      color: #393939;
    }
  }
  .MuiPaper-root {
    background-color: transparent;
  }
  .MuiTouchRipple-child {
    background-color: rgb(63, 81, 181);
  }
}

.TabItem-underline {
  position: relative;
  width: fit-content;
  &::before {
    content: "";
    border: 2px solid rgb(218, 0, 0);
    position: absolute;
    height: 2px;
    width: calc(100% + 20px);
    left: -10px;
    bottom: -9px;
  }
}

.Item-notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 15% 0;
  color: #b0b0b0;
  & img {
    height: 4.375rem;
    filter: brightness(0) invert(0.7);
  }
  & svg {
    font-size: 4.375rem;
    margin-bottom: -0.75rem;
    color: #b0b0b0;
  }
  & > div > div {
    font-size: 18px;
    font-weight: bold;
    margin-top: 30px;
  }
}
