/* Tab */
.medication-tab-custom {
  width: 100%;
  background-color: white;

  .ui.pointing.secondary.menu {
    border-bottom: 0;
    margin-bottom: 2.5px;
    &:hover,
    .active.item {
      text-align: center;
      justify-content: center;
      border-color: var(--primary-theme-color);
      border-bottom-width: 3px;
      color: var(--primary-theme-color);
    }

    .item {
      width: 50%;
      justify-content: center;
      text-align: center;
      font-size: 16px;
      border-color: rgb(34 36 38 / 10%);
      border-bottom-width: 3px;
      color: #9e9e9e;
    }
  }
}

.medication-search {
  .ui.input {
    width: 100%;
  }
  .ui.search .prompt {
    border-radius: 10px;
  }
}

.medication-selectDose .MuiAccordion-root {
  .MuiAccordionSummary-root {
    .MuiAccordionSummary-content {
      margin: 0;
    }

    .Mui-expanded {
      margin: 0;
    }
  }

  .MuiAccordionDetails-root {
    margin: 0;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

div.hb-container {
  background-color: var(--primary-theme-color);
  height: 50px;
  display: grid;
  grid-template-columns: 60px auto 60px;
  align-items: center;
  font-size: 20px;
  & .left.icon {
    color: white;
  }
  & .hb-title-bar {
    color: white;
  }
}

div.hb-white-theme {
  &.MuiContainer-root {
    background-color: white;
  }
  & .left.icon {
    color: rgba(57, 57, 57, 1);
  }
  & .hb-title-bar {
    color: rgba(57, 57, 57, 1);
    font-weight: bold;
    font-size: 1.1rem;
  }
  & .right-icon img {
    filter: invert(0.8);
  }
}

div.hb-box-bg-gradient {
  z-index: 1;
  position: relative;
  background: transparent;
  &.hb-white-theme {
    background: transparent;
  }
  ~ .bg-gradient {
    z-index: 0;
    width: 100%;
    position: absolute;
    top: 0;
    pointer-events: none;
    height: 50px;
    overflow: hidden;
    background-color: white;
    &:after {
      content: " ";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='375' height='813' viewBox='0 0 375 813' fill='none'%3E%3Crect width='375' height='813' fill='white'/%3E%3Crect width='375' height='813' fill='url(%23paint0_linear_5556_262872)' fill-opacity='0.2'/%3E%3Cpath opacity='0.05' d='M203 0H0.5V361.5L203 0Z' fill='%230147A3'/%3E%3Cpath opacity='0.05' d='M374.5 488.5V807.5H198L374.5 488.5Z' fill='%230147A3'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_5556_262872' x1='187' y1='877.352' x2='187' y2='8.66841e-07' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%230147A3'/%3E%3Cstop offset='0.866526' stop-color='%230147A3' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: calc(100vh - 0px);
      position: absolute;
      left: 0;
      opacity: 0.5;
    }
  }
}

.hb-title-bar {
  display: flex;
  text-align: center;
  align-items: center;
  color: white;
  justify-content: center;
  font-size: 1rem;
}
