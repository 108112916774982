
/* Tab */
.lab-tab-custom {
    width: 100%;
    background-color: white;
   
    .ui.pointing.secondary.menu{
      border-bottom: 0;
      margin-bottom: 2.5px;
      &:hover,
      .active.item {
        text-align: center;
        justify-content: center;
        border-color: var(--primary-theme-color);
        border-bottom-width: 3px;
        color: var(--primary-theme-color);
      }
  
      .item {
        width: 33.33%;
        justify-content: center;
        text-align: center;
        font-size: 16px;
        border-color: rgb(34 36 38 / 10%);
        border-bottom-width: 3px;
        color: #9e9e9e;
      }
    }
  }

.medication_search{
  .ui.input{
    width: 100%;
  }
  .ui.search .prompt{
    border-radius: 10px;
  }
}