.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded {
  box-shadow: 0px 0px 8px 0px rgba(131, 133, 135, 0.2) !important;
}
.MuiList-root.MuiMenu-list {
  li {
    min-height: 45px;
    font-size: 16px;
    padding: 6px 16px !important;
    width: 100%;
    justify-content: flex-start;
    span {
      color: #0147a3;
    }
  }
}
